import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  restPasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authServ: AuthService
  ) { }

  ngOnInit() {
    // Init reset password form
    this.restPasswordForm = this.formBuilder.group({
      email: [null, Validators.compose([
        Validators.email,
        Validators.required])]
    });
  }

  // convenience getter for easy access to form fields
  get formAlias() { return this.restPasswordForm.controls; }

  /**
   * Request to reset password
   */
  resetPasswordReq() {
    this.authServ.sendResetPasswordRequest(this.restPasswordForm.value).subscribe(() => {
      this.router.navigate(['/reset-success']);
    })
  }
}
