import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  loader: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authServ: AuthService
  ) { }

  ngOnInit() {
    // Init signin form
    this.signinForm = this.formBuilder.group({
      email: [null, Validators.compose([
        Validators.email,
        Validators.required])],
      password: [null, Validators.compose([
        Validators.required
      ])],
    });
  }

  // convenience getter for easy access to form fields
  get formAlias() { return this.signinForm.controls; }

  /**
   * Redirect to page
   * @param pagePath: page path
   */
  redirectTo(pagePath) {
    this.router.navigate([pagePath]);
  }

  /**
   * User signin
   */
  signin() {
    if (this.signinForm.valid) {
      this.loader = true;
      this.authServ.signin(this.signinForm.value).subscribe(() => {
        this.loader = false;
        this.router.navigate(['/']);
      }, error => {
        this.loader = false;
        throw error;
      });
    }
  }
}
