import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditOfferComponent } from 'src/app/backoffice/company/offers/edit-offer/edit-offer.component';
import { DataService } from 'src/app/core/services/data/data.service';

@Component({
  selector: 'app-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss']
})
export class ListBoxComponent implements OnInit {
  @Input() items: any;
  @Output() onRemoveItemEmit = new EventEmitter<string>();

  // Temporary until we know hwitch image we should display
  @Input() companyLogo: any;

  // TO REPLACE WITH item.offerLogo
  defaultOfferLogoImage = 'https://static.bayard.io/e-bayard-jeunesse.com/2020/offre-cadeau/boutique/home/bloc1/images/bj-mars-2020-kids-promo.gif';
  imageLoader = true;
  logoImageLoader = true;

  constructor(
    public dialog: MatDialog,
    private dataServ: DataService
  ) { }

  ngOnInit() {
  }

  /**
   * On remove item select
   * @param item : item to remove
   */
  onRemoveItem(item: any) {
    this.onRemoveItemEmit.emit(item);
  }

  /**
   * Open dialog to edit offer
   * @param item : offer item
   */
  openEditOfferDialog(item: any) {
    const dialogRef$ = this.dialog.open(EditOfferComponent, {
      panelClass: 'edit-offer-dialog',
      hasBackdrop: true,
      disableClose: true,
      data: {
        offer: item
      }
    });

    dialogRef$.beforeClosed().subscribe(confirmationReponse => {
      if (confirmationReponse === 'success') {
        this.dataServ.setRefreshOfferList(true);
      }
    }, (error: any) => { throw error; });
  }

  /**
   * Hide image loader
   */
  hideLoader() {
    this.imageLoader = false;
  }

  /**
   * Hide logo image loader
   */
  hideLogoLoader() {
    this.logoImageLoader = false;
    this.companyLogo ? this.companyLogo : this.companyLogo = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMFTN2JLFecn41bpE6dI95VHe90_5I1fJzC5RPJeVdBXwSw1wA&s';
  }
}
