import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../custom-validators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Router } from '@angular/router';
import { GlobalNotifierService } from 'src/app/core/services/global-notifier/global-notifier.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  termsStatus: boolean;
  loader: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authServ: AuthService,
    private router: Router,
    private notifierServ: GlobalNotifierService
  ) { }

  ngOnInit() {
    // Init signup form
    this.signupForm = this.formBuilder.group({
      name: [null, Validators.compose([
        Validators.required])],
      lastname: [null, Validators.compose([
        Validators.required])],
      email: [null, Validators.compose([
        Validators.email,
        Validators.required])],
      company: [null, Validators.compose([
        Validators.required])],
      acceptConditions: [true],
      password: [null, Validators.compose([
        Validators.required
      ])],
      confirmPassword: [null, Validators.compose([Validators.required])]
    }, {
      validator: CustomValidators.passwordMatchValidator
    });
  }

  // convenience getter for easy access to form fields
  get formAlias() { return this.signupForm.controls; }

  /**
   * Redirect to page
   * @param pagePath: page path
   */
  redirectTo(pagePath) {
    this.router.navigate([pagePath]);
  }

  /**
   * Redirect to extern link terms & conditions
   */
  redirectToTerms() {
    this.router.navigate(['/conditions-generales']);
  }

  /**
   * Signup new user
   */
  signup() {
    if (this.termsStatus && this.signupForm.valid) {
      this.loader = true;
      this.authServ.signup(this.signupForm.value).subscribe(() => {
        this.notifierServ.displayNotification('success', 'Success', 'Votre compte a été créé avec succès!');
        this.loader = false;
        this.router.navigate(['/signin']);
      }, error => {
        this.loader = false;
        throw error;
      });
    }
  }
}
