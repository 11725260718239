import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpReqInterceptorService {

  constructor(private http: HttpClient, private authServ: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 'GET_TOKEN_FROM_SERVICE'
    const userToken = this.authServ.getStoredUserToken();

    const updatedRequest: HttpRequest<any> = req.clone({
      setHeaders: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return next.handle(updatedRequest);
  }
}
