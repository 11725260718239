import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OfferService {
  constructor(private http: HttpClient) {}

  /**
   * Get company offers
   */
  getOffers(): Observable<any> {
    return this.http.get(
      environment.compapiURL +
        "company-service/v1/api/v1/manage/offers/company/get?url=true"
    );
  }

  /**
   * Add new offer
   * @param offerData : offer data
   */
  addNewOffer(offerData: any): Observable<any> {
    return this.http.post(
      environment.compapiURL + "company-service/v1/api/v1/manage/offers/add",
      offerData
    );
  }

  /**
   * Update offer
   * @param offerGuid : offer GUID
   * @param offerData : offer data
   */
  updateOffer(offerGuid: string, offerData: any): Observable<any> {
    return this.http.post(
      environment.compapiURL +
        "company-service/v1/api/v1/manage/offers/" +
        offerGuid +
        "/update",
      offerData
    );
  }

  /**
   * Remove offer
   * @param offerGuid : offer GUID
   */
  removeOffer(offerGuid: any): Observable<any> {
    return this.http.delete(
      environment.compapiURL +
        "/company-service/v1/api/v1/manage/offers/remove/" +
        offerGuid
    );
  }

  /**
   * Upload offer image
   * @param offerGuid : offer Guid
   * @param imageData : image data
   */
  uploadOfferImage(offerGuid: string, imageData: any): Observable<any> {
    return this.http.post(
      environment.compapiURL +
        "/company-service/v1/api/v1/manage/offers/" +
        offerGuid +
        "/logo/upload",
      imageData
    );
  }
}
