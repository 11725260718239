import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './public/not-found/not-found.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { SigninComponent } from './public/signin/signin.component';
import { SignupComponent } from './public/signup/signup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResetSuccessComponent } from './public/reset-success/reset-success.component';
import { HttpClientModule } from '@angular/common/http';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { MAT_DATE_LOCALE } from '@angular/material';
import { CgvComponent } from './public/cgv/cgv.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    SigninComponent,
    SignupComponent,
    ResetSuccessComponent,
    CgvComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    SnotifyModule
  ],

  providers: [
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    },
    SnotifyService,
    {provide: MAT_DATE_LOCALE, useValue: 'fr'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
