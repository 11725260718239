import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from "../storage/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  companySections = [
    {
      name: "Ma société",
      value: "myCompany",
      valid: false,
      subs: [
        {
          name: "Informations",
          value: "informations",
          valid: false,
        },
        {
          name: "Points de ventes",
          value: "sales-point",
          valid: false,
        },
      ],
    },
    {
      name: "Mon programme fidélité",
      value: "fidelityProgram",
      valid: false,
      subs: [
        {
          name: "Informations",
          value: "fidelity-informations",
          valid: false,
        },
        {
          name: "Valeur et partage",
          value: "fidelity-share",
          valid: false,
        },
        {
          name: "Mes offres",
          value: "offers",
          valid: false,
        },
      ],
    },
    {
      name: "Coordonnées bancaires",
      value: "fidelityProgram",
      valid: false,
      subs: [
        {
          name: "Pack et payment",
          value: "payment",
          valid: false,
        },
        {
          name: "Conditions générales de vente",
          value: "payment/conditions",
          valid: false,
        },
      ],
    },
  ];

  initSectionsCopy = [...this.companySections];

  // Company sidebar sections
  public companySideBarSections$: BehaviorSubject<any>;
  // Selected pack offer
  public selectedPack$: BehaviorSubject<any>;
  // Refresh programme list
  public refreshProgramList$: BehaviorSubject<any>;
  // Refresh offer list
  public refreshOfferList$: BehaviorSubject<any>;
  // Refresh points of sales list
  public refreshSalesPtList$: BehaviorSubject<any>;
  // Refresh companyAssets
  public refreshCompanyAssets$: BehaviorSubject<any>;
  // Company account exist
  public companyAccountExist$: BehaviorSubject<boolean>;

  constructor(private localStorageServ: LocalStorageService) {
    this.initCompanySections();
    this.selectedPack$ = new BehaviorSubject(null);
    this.refreshProgramList$ = new BehaviorSubject(null);
    this.refreshSalesPtList$ = new BehaviorSubject(null);
    this.refreshCompanyAssets$ = new BehaviorSubject(null);
    this.companyAccountExist$ = new BehaviorSubject(null);
    this.refreshOfferList$ = new BehaviorSubject(null);
  }

  /**
   * Init company sideBar sections
   */
  initCompanySections() {
    const savedSections = this.localStorageServ.getItem("companySection");

    // Check sotored companySections infos
    if (!savedSections) {
      this.localStorageServ.setItem("companySection", this.companySections);
      this.companySideBarSections$ = new BehaviorSubject(this.companySections);
    } else {
      this.companySections = savedSections;
      this.companySideBarSections$ = new BehaviorSubject(savedSections);
    }
  }

  refreshCompanySections() {
    this.localStorageServ.setItem("companySection", this.initSectionsCopy);
    this.companySideBarSections$ = new BehaviorSubject(this.initSectionsCopy);
  }

  /**
   * Set compnay sideBar sections
   * @param infosData: string
   */
  setCompanySideBarSection(infosData: any) {
    switch (infosData.section) {
      // Ma societe && stripe plan
      case "company":
        infosData.infos.companyName
          ? (this.companySections[0].subs[0].valid = true)
          : (this.companySections[0].subs[0].valid = false);
        infosData.infos.pointOfSales
          ? (this.companySections[0].subs[1].valid = true)
          : (this.companySections[0].subs[1].valid = false);
        infosData.infos.companyName && infosData.infos.pointOfSales
          ? (this.companySections[0].valid = true)
          : (this.companySections[0].valid = false);
        infosData.stripeplan
          ? (this.companySections[2].subs[0].valid = true)
          : (this.companySections[2].subs[0].valid = false);
        break;
      // Mon programme fidelite subs
      case "company-prog":
        infosData.infos.companyProgramName
          ? (this.companySections[1].subs[0].valid = true)
          : (this.companySections[1].subs[0].valid = false);
        break;
      // Mon value et partage
      case "value-share":
        infosData.infos.ratioValue
          ? (this.companySections[1].subs[1].valid = true)
          : (this.companySections[1].subs[1].valid = false);
        break;
      // Mes offres
      case "offers":
        infosData.infos.offers
          ? (this.companySections[1].subs[2].valid = true)
          : (this.companySections[1].subs[2].valid = false);
        break;
    }

    // Mon programme fidelite section
    this.companySections[1].subs[0].valid &&
    this.companySections[1].subs[1].valid &&
    this.companySections[1].subs[2].valid
      ? (this.companySections[1].valid = true)
      : (this.companySections[1].valid = false);
    // Coordonnees bancaire
    this.companySections[2].subs[0].valid &&
    this.companySections[2].subs[1].valid
      ? (this.companySections[2].valid = true)
      : (this.companySections[2].valid = false);

    // Update company sections
    this.companySideBarSections$.next(this.companySections);
    this.localStorageServ.setItem("companySection", this.companySections);
  }

  /**
   * Set selected pack offer
   * @param selectedPack : starter:0, pro:1, business:2
   */
  setSelectedPack(selectedPack: any) {
    this.selectedPack$.next(selectedPack);
  }

  /**
   * Set refresh prog list
   * @param refresh : true || false
   */
  setRefreshProgList(refresh: any) {
    this.refreshProgramList$.next(refresh);
  }

  /**
   * Set refresh prog list
   * @param refresh : true || false
   */
  setRefreshOfferList(refresh: any) {
    this.refreshOfferList$.next(refresh);
  }

  /**
   * Set refresh sales points list
   * @param refresh : true || false
   */
  setRefreshSalesPtList(refresh: any) {
    this.refreshSalesPtList$.next(refresh);
  }

  /**
   * Set refresh company assets
   * @param refresh : true || false
   */
  setRefreshCompanyAssets(refresh: any) {
    this.refreshCompanyAssets$.next(refresh);
  }

  /**
   * Set company account existance
   * @param refresh : true || false
   */
  setCompanyAccountExistance(exist: boolean) {
    this.companyAccountExist$.next(exist);
  }
}
