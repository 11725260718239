import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalNotifierService } from 'src/app/core/services/global-notifier/global-notifier.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { OfferService } from '../../services/offer/offer.service';
import { CompanyService } from '../../services/company/company.service';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class EditOfferComponent implements OnInit {
  editOfferForm: FormGroup;

  loader = false;
  offerCategories = ['Vente', 'Sport', 'Beauté', 'Mode', 'Alimentation', 'Bricolage',
    'Voyage', 'Maison', 'Auto-moto', 'enfants', 'vins et gastronomie', 'Loisir', 'Autre', 'Tous'];
  companyName: any;
  updateImageData: any;
  salesPoints = [];


  constructor(
    public dialogRef: MatDialogRef<EditOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { offer: any },
    private authServ: AuthService,
    private offerServ: OfferService,
    private notificationServ: GlobalNotifierService,
    private companyServ: CompanyService
  ) { }

  offer: any;

  ngOnInit() {
    this.offer = this.data.offer;

    this.companyName = this.authServ.getStoredUser().company;

    this.getSalesPoints();

    this.editOfferForm = new FormGroup({
      name: new FormControl(this.offer.name, Validators.required),
      offerDescription: new FormControl(this.offer.offerDescription, [Validators.maxLength(225), Validators.required]),
      offerCategory: new FormControl(this.offer.offerCategory, Validators.required),
      expirationDate: new FormControl(this.offer.expirationDate, Validators.required),
      fidly_pts: new FormControl(this.offer.fidly_pts, Validators.required),
      location: new FormControl(this.offer.location, Validators.required),
      company: new FormControl(this.companyName, Validators.required),
    });
  }

  // convenience getter for easy access to form fields
  get formAlias() { return this.editOfferForm.controls; }

  /**
   * Get sales points
   */
  getSalesPoints() {
    this.companyServ.getCompanyInformations().subscribe(
      response => this.salesPoints = response.info.pointOfSales
    );
  }

  /**
   * Get new logo data
   * @param logoData : logoData
   */
  getNewLogoData(logoData: any) {
    this.updateImageData = logoData;
  }

  /**
   * Update offer
   */
  updateOffer() {
    this.loader = true;

    // this.dialogRef.close('success');

    this.offerServ.updateOffer(this.offer.guid, this.editOfferForm.value).subscribe(
      (response) => {
        if (response) {
          // Upload offer image
          if (this.updateImageData) {
            this.uploadOfferImage();
          }

          this.notificationServ.displayNotification('success', 'Succès', 'Votre offre a été modifiée avec succès');
          this.dialogRef.close('success');
          this.loader = false;
        }
      },
      error => this.loader = false
    )
  }

  /**
   * Upload offer image
   */
  uploadOfferImage() {
    this.offerServ.uploadOfferImage(this.offer.guid, this.updateImageData).subscribe(
      (response) => {
        if (response) {
          this.notificationServ.displayNotification('success', 'Succès', 'Votre Image d\'offre est à jour');
        }
      }
    )
  }
}
