import { Injectable } from '@angular/core';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class GlobalNotifierService {

  constructor(private snotifyService: SnotifyService) { }

  /**
   * @param string notifType - Possible values: 'success' || 'info' || 'warning' || 'error'.
   * @param string notifTitle - The notification title text
   * @param string notifText - The notification content text 
  */
  displayNotification(notifType: string, notifTitle: string, notifText: string): void {
    this.snotifyService[notifType](notifText, notifTitle, {
      timeout: 2000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true
    });
  }

}
