import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatRadioModule,
  MatIconModule,
  MatTableModule,
  MatPaginatorModule,
  MatMenuModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { AddBoxComponent } from './components/add-box/add-box.component';
import { ListBoxComponent } from './components/list-box/list-box.component';
import { GeneralConditionsComponent } from './components/general-conditions/general-conditions.component';
import { CguComponent } from './components/cgu/cgu.component';
import { TablePlaceholderComponent } from './components/table-placeholder/table-placeholder.component';

@NgModule({
  declarations: [
    GaugeChartComponent,
    StackedBarChartComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    UploadImageComponent,
    AddBoxComponent,
    ListBoxComponent,
    GeneralConditionsComponent,
    CguComponent,
    TablePlaceholderComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    AlertDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,


    // Material modules
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatRadioModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    StackedBarChartComponent,

    // Shared components
    GaugeChartComponent,
    UploadImageComponent,
    AddBoxComponent,
    ListBoxComponent,
    GeneralConditionsComponent,
    CguComponent,
    TablePlaceholderComponent,

    // Material modules
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatRadioModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ]
})
export class SharedModule { }
