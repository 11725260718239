import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../../models/user";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "../storage/local-storage.service";
import { Router } from "@angular/router";
import { DataService } from "../data/data.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  protected authenticatedUser$: BehaviorSubject<User> = new BehaviorSubject<
    User
  >(null);

  constructor(
    private http: HttpClient,
    private localStorageServ: LocalStorageService,
    private dataServ: DataService,
    private router: Router,
    private zone: NgZone
  ) {}

  /**
   * Signin by storing the returned user and token in session storage
   * @param emailAndPassword object - signin data
   *
   */
  signin(emailAndPassword: { email: string; password: string }) {
    return this.http
      .post(
        environment.authapiURL +
          "authentification/v1/companyadmin/login?fullUser=true",
        emailAndPassword
      )
      .pipe(
        map(
          (result: any) => {
            if (result) {
              this.localStorageServ.setItem("user", result.user);
              this.localStorageServ.setItem("token", result.token);
              this.authenticatedUser$.next(result.data);

              const lastUserConnected = this.localStorageServ.getItem(
                "lastUserConnected"
              );

              if (lastUserConnected) {
                if (lastUserConnected !== result.user.company) {
                  this.localStorageServ.setItem(
                    "lastUserConnected",
                    result.user.company
                  );
                  this.localStorageServ.removeItem("companySection");
                  this.dataServ.refreshCompanySections();
                }
              } else {
                this.localStorageServ.setItem(
                  "lastUserConnected",
                  result.user.company
                );
              }

              return true;
            }
            throw new Error("Invalid api success response");
          },
          (error: any) => {
            throw error;
          }
        )
      );
  }

  /**
   * Signup then storing the returned user and token in session storage
   * @param signupData
   */
  signup(signupData: object) {
    return this.http.post(
      environment.authapiURL + "authentification/v1/companyadmin/signup",
      signupData
    );
  }

  /**
   * Signout & clear storage
   */
  signout(): void {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    this.authenticatedUser$.next(null);
    this.zone.run(() => this.router.navigate(["signin"]));
  }

  /**
   * Verify user sign status
   */
  checkIfUserSignedIn(): boolean {
    const user = this.localStorageServ.getItem("user");
    return user ? true : false;
  }

  /**
   * Get user token
   */
  getStoredUserToken(): string {
    const token = this.localStorageServ.getItem("token");
    return token ? token : null;
  }

  /**
   * Get authenticated user
   */
  getStoredUser(): User | null {
    const user = this.localStorageServ.getItem("user");
    return user ? user : null;
  }

  getAuthenticatedUserSubject(): Observable<User> {
    return this.authenticatedUser$;
  }

  getAuthenticatedUserValue(): User {
    return this.authenticatedUser$.value;
  }

  /**
   * Update authenticated user
   * @param updatedUser: object - user to update
   */
  updateAuthenticatedUser(updatedUser: User): void {
    this.localStorageServ.setItem("user", updatedUser);
    this.authenticatedUser$.next(updatedUser);
  }

  /**
   * Send reset password
   * @param userCredentials: object - user email
   */
  sendResetPasswordRequest(userCredentials: { email: string }) {
    return this.http.post(
      environment.authapiURL + "auth/reset",
      userCredentials
    );
  }
}
