import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  @Input() imageTitle: string;
  @Input() imageUrl: any;
  @Input() imageLoader: any;
  
  @Output() newLogoEmit = new EventEmitter<any>();

  @ViewChild('fileInput') el: ElementRef;

  imageName: string;

  constructor(
    private changeDetect: ChangeDetectorRef
  ) { }


  ngOnInit() {
    this.imageUrl = this.imageUrl ? this.imageUrl : null;
  }

  /**
   * Upload Image
   * @param event: Event
   */
  uploadImage(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    // Get file name
    if (file) {
      this.imageName = file.name.split('.').shift();
    }

    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;

        // Emit image value to the parent form
        // Need to split image data def 'data:image/png;base64,'
        this.newLogoEmit.emit({
          name: this.imageName,
          img: this.imageUrl.split(',')[1]
        })
      }

      // ChangeDetectorRef since file is loading outside the zone
      this.changeDetect.markForCheck();
    }
  }

}
