import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './public/signin/signin.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { NotFoundComponent } from './public/not-found/not-found.component';
import { SignupComponent } from './public/signup/signup.component';
import { ResetSuccessComponent } from './public/reset-success/reset-success.component';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { CgvComponent } from './public/cgv/cgv.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'backoffice'
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'reset',
    component: ResetPasswordComponent
  },
  {
    path: 'conditions-generales',
    component: CgvComponent
  },
  {
    path: 'reset-success',
    component: ResetSuccessComponent
  },
  {
    path: 'backoffice',
    loadChildren: 'src/app/backoffice/backoffice.module#BackofficeModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'notfound',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/notfound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
