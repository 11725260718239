import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  /**
   * Get company informations
   */
  getCompanyInformations(): Observable<any> {
    return this.http.get(
      environment.compapiURL +
        "company-service/v1/api/v1/company/getInformation?url=true"
    );
  }

  /**
   * Update company informations
   * @param companyData : Company data
   */
  updateCompanyInformations(companyData: any): Observable<any> {
    return this.http.post(
      environment.compapiURL +
        "company-service/v1/api/v1/manage/company/updateInformation",
      companyData
    );
  }

  /**
   * Add new company
   * @param companyData : company data
   */
  addNewCompany(companyData: any): Observable<any> {
    return this.http.post(
      environment.compapiURL + `/company-service/v1/api/v1/manage/company/add`,
      companyData
    );
  }

  /**
   * Upload company Logo
   * @param logoData : logoData {name, img}
   */
  uploadCompanyLogo(logoData: any): Observable<any> {
    return this.http.post(
      environment.compapiURL +
        "company-service/v1/api/v1/manage/company/logo/upload",
      logoData
    );
  }
}
