import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-table-placeholder',
  templateUrl: './table-placeholder.component.html',
  styleUrls: ['./table-placeholder.component.scss']
})
export class TablePlaceholderComponent implements OnInit {

  @Input() tableLoaderState: any;

  constructor() { }

  ngOnInit() {
  }

}
