import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data/data.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  constructor(
    private router: Router,
    private dataServ: DataService,
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      alertMessage: string,
      redirectTo: string,
      buttonText: string,
      nextSection: string
    }
  ) { }

  ngOnInit() {
  }

  /**
   * Redirect to
   */
  redirectTo() {
    this.dialogRef.close();
    this.router.navigate([this.data.redirectTo]);
  }
}
