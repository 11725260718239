import {
  Component,
  ViewEncapsulation,
  EventEmitter,
  TemplateRef,
  Input,
  Output,
  ContentChild,
  ChangeDetectionStrategy
} from '@angular/core';

import {
  BaseChartComponent,
  ViewDimensions,
  ColorHelper,
  calculateViewDimensions
} from '@swimlane/ngx-charts';

import { trigger, style, animate, transition } from '@angular/animations';
import { scaleBand, scaleLinear } from 'd3-scale';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' }))
      ])
    ])
  ]
})

export class StackedBarChartComponent extends BaseChartComponent {
  @Input() legend = false;
  @Input() legendTitle: string = 'Legend';
  @Input() legendPosition: string = 'right';
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() tooltipDisabled: boolean = false;
  @Input() gradient: boolean;
  @Input() showGridLines: boolean = true;
  @Input() activeEntries: any[] = [];
  @Input() schemeType: string;
  @Input() trimXAxisTicks: boolean = true;
  @Input() trimYAxisTicks: boolean = true;
  @Input() rotateXAxisTicks: boolean = true;
  @Input() maxXAxisTickLength: number = 16;
  @Input() maxYAxisTickLength: number = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yAxisTicks: any[];
  @Input() barPadding = 50;
  @Input() roundDomains: boolean = false;
  @Input() yScaleMax: number;
  @Input() showDataLabel: boolean = false;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero: boolean = true;
  @Input() chartStyle: any;
  @Input() chartTitle: string;
  @Input() chartId: string;
  @Input() chartTooltipTxt: string;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  groupDomain: any[];
  innerDomain: any[];
  valueDomain: any[];
  xScale: any;
  yScale: any;
  transform: string;
  tickFormatting: (label: string) => string;
  colors: ColorHelper;
  margin = [20, 30, 20, 30];
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  legendOptions: any;
  dataLabelMaxHeight: any = { negative: 0, positive: 0 };
  maxWidth: any;
  maxBandWidth = 80;
  xAxisWidth: any;
  rect: any;

  update(): void {
    super.update();

    if (!this.showDataLabel) {
      this.dataLabelMaxHeight = { negative: 0, positive: 0 };
    }
    this.margin = [30 + this.dataLabelMaxHeight.positive, 30, 30 + this.dataLabelMaxHeight.negative, 30];

    this.dims = calculateViewDimensions({
      width: (this.width - 40), // offset equal 25
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }

    this.maxWidth = Math.min(this.maxBandWidth * this.results.length, this.dims.width);

    this.formatDates();

    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valueDomain = this.getValueDomain();

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;

    const xmlns = 'http://www.w3.org/2000/svg';

    /**
     * Append xAxis rect to each chart
     */
    if (this.chartId === 'pointsChart') {
      const elemts = document.getElementById(this.chartId).querySelectorAll('.xAxisClass .tick');
      let xAxisElementById = document.getElementById(this.chartId).getElementsByClassName('xAxisClass')[0];

      // get xAxis Width
      if (xAxisElementById) {
        this.xAxisWidth = xAxisElementById.getBoundingClientRect().width;
      }

      // calculate xAxis rect Width
      const rectWidth = this.xAxisWidth / this.results.length;

      // Remove existing rectangle to avoid duplication
      const elements = document.getElementById(this.chartId).getElementsByClassName('rect-border');
      while (elements.length > 0) elements[0].remove();

      // Append new rectagle based on width resize
      for (let i = 0; i < elemts.length; i++) {
        this.rect = document.createElementNS(xmlns, 'rect');
        this.rect.setAttributeNS(null, 'x', '-35');
        this.rect.setAttributeNS(null, 'y', '-15');
        this.rect.setAttributeNS(null, 'height', '9');
        this.rect.setAttributeNS(null, 'width', `${rectWidth}`);
        this.rect.setAttributeNS(null, 'stroke-dasharray', `${(rectWidth + 9) + ',' + rectWidth}`);
        this.rect.setAttributeNS(null, 'class', 'rect-border');

        elemts[i].appendChild(this.rect);
      }
      // End of Add xAxis Rectangle / border
    } else {
      const elemts = document.getElementById(this.chartId).querySelectorAll('.xAxisClass .tick');
      var xAxisElementById = document.getElementById(this.chartId).getElementsByClassName('xAxisClass')[0];

      // get xAxis Width
      if (xAxisElementById) {
        this.xAxisWidth = xAxisElementById.getBoundingClientRect().width;
      }

      // calculate xAxis rect Width
      const rectWidth = this.xAxisWidth / this.results.length;

      // Remove existing rectangle to avoid duplication
      const elements = document.getElementById(this.chartId).getElementsByClassName('rect-border');
      while (elements.length > 0) elements[0].remove();

      // Append new rectagle based on width resize
      for (let i = 0; i < elemts.length; i++) {
        this.rect = document.createElementNS(xmlns, 'rect');
        this.rect.setAttributeNS(null, 'x', '-35');
        this.rect.setAttributeNS(null, 'y', '-15');
        this.rect.setAttributeNS(null, 'height', '9');
        this.rect.setAttributeNS(null, 'width', `${rectWidth}`);
        this.rect.setAttributeNS(null, 'stroke-dasharray', `${(rectWidth + 9) + ',' + rectWidth}`);
        this.rect.setAttributeNS(null, 'class', 'rect-border');

        elemts[i].appendChild(this.rect);
      }
      // End of Add xAxis Rectangle / border
    }
    // End of Append xAxis rect to each chart
  }

  getGroupDomain() {
    const domain = [];
    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }
    return domain;
  }

  getInnerDomain() {
    const domain = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label)) {
          domain.push(d.label);
        }
      }
    }
    return domain;
  }

  getValueDomain() {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    for (const group of this.results) {
      let smallestSum = 0;
      let biggestSum = 0;
      for (const d of group.series) {
        if (d.value < 0) {
          smallestSum += d.value;
        } else {
          biggestSum += d.value;
        }
        smallest = d.value < smallest ? d.value : smallest;
        biggest = d.value > biggest ? d.value : biggest;
      }
      domain.push(smallestSum);
      domain.push(biggestSum);
    }
    domain.push(smallest);
    domain.push(biggest);

    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
  }

  getXScale(): any {
    const spacing = this.groupDomain.length / (this.maxWidth / this.barPadding + 1);
    const offset = 25;

    return scaleBand()
      .rangeRound([offset, this.maxWidth + offset])
      .paddingInner(spacing)
      .domain(this.groupDomain);
  }

  getYScale(): any {
    const scale = scaleLinear()
      .range([this.dims.height, 0])
      .domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  onDataLabelMaxHeightChanged(event, groupIndex) {
    if (event.size.negative) {
      this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    } else {
      this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if (groupIndex === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  groupTransform(group) {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
  }

  onClick(data, group?) {
    if (group) {
      data.series = group.name;
    }

    this.select.emit(data);
  }

  trackBy(index, item) {
    return item.name;
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.innerDomain;
    } else {
      domain = this.valueDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valueDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateYAxisWidth({ width }) {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }) {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    const items = this.results
      .map(g => g.series)
      .flat()
      .filter(i => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    this.activeEntries = this.activeEntries.filter(i => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
}
