import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }
  /**
 * Set item in localStorage
 * @param key: string - key
 * @param value: any - value
 */
  setItem(key: string, value: any) {
    localStorage.setItem(`${key}`, JSON.stringify(value));
  }

  /**
   * GetItem from localStorage
   * @param key: string
   */
  getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${key}`));
  }

  /**
   * RemoveItem from localStorage
   * @param key: string
   */
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Clear storage
   */
  clearStorage() {
    localStorage.clear();
  }
}
