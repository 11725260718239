import { Injectable, ErrorHandler } from '@angular/core';
import { GlobalNotifierService } from '../global-notifier/global-notifier.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private readonly DEFAULT_ERROR_TITLE: string = 'Oups ! Une erreur interne s\'est produite,nous ne pouvons pas traiter votre demande pour le moment, veuillez réessayer ultérieurement';

  constructor(
    private globalNotifServ: GlobalNotifierService,
    private authServ: AuthService
  ) { }

  public handleError(error: any) {
    // display notification to let user know an error happened
    if (error instanceof HttpErrorResponse) {
      const errorHttpCode = error.status;
      const errorHttpMessage = error.error.message;
      const expiredErrorMessage = 'Votre token est expiré, veuillez vous connecter à nouveau.';
      const wrongCredentialErrorMessage = 'E-mail ou mot de passe incorrect. Réessayez ou cliquez sur "Mot de passe oublié ?" pour le réinitialiser.'

      switch (errorHttpCode) {
        case 500:
          if (errorHttpMessage === 'jwt expired') {
            this.globalNotifServ.displayNotification('error', 'Erreur', expiredErrorMessage);
            this.authServ.signout();
          } else if (errorHttpMessage === 'Password provided is incorrect') {
            this.globalNotifServ.displayNotification('error', 'Erreur', wrongCredentialErrorMessage);
          } else {
            this.globalNotifServ.displayNotification('error', 'Erreur', this.DEFAULT_ERROR_TITLE);
          }
          break;
        case 0:
          this.globalNotifServ.displayNotification('error', 'Erreur', this.DEFAULT_ERROR_TITLE);
          break;
        default:
          console.error('A http error occured :', error);
          this.globalNotifServ.displayNotification('error', 'Erreur', error.error.message);
          break;
      }
    } else {
      this.globalNotifServ.displayNotification('error', 'Erreur', this.DEFAULT_ERROR_TITLE);
      console.error('An error occured :', error);
    }
  }
}
