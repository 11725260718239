import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-box',
  templateUrl: './add-box.component.html',
  styleUrls: ['./add-box.component.scss']
})
export class AddBoxComponent implements OnInit {
  @Input() addText: string;
  @Output() addEventEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Emit addEvent when click
   */
  emitAddEvent() {
    this.addEventEmitter.emit();
  }
}
