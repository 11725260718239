// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: "develop",
  authapiURL:
    "https://prod001-dot-authentification-dot-fidly-251719.appspot.com/",
  apiURL: "https://prod001-dot-fidly-251719.appspot.com/",
  compapiURL: "https://prod001-dot-company-dot-fidly-251719.appspot.com/",
  stellarapiURL:
    "https://prod001-dot-stellar-wallet-dot-fidly-251719.appspot.com/",
  stripePublicKey: "pk_test_nmvkrnE4fvD5z8Tgy4dPZO7O000wmKGkP1",
  appVersion: "0.1.22",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
