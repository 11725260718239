import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit {
  @Input() parentDivColor: string;
  @Input() gaugeActiveValue: number;
  @Input() capTitle: string;
  @Input() capSubTitle: string;
  @Input() capSubTitleValue: string;
  @Input() showSubs: boolean;
  @Input() gaugeTitle: string;
  @Input() gaugeBgColor: string;
  @Input() gaugeGradientStyle: boolean;
  @Input() minValue: string;
  @Input() maxValue: string;

  // PS: gaugeActiveValue should be the pourcentage for the gauge not the data
  // Example: dataPourcentage = 20 (pourcentage)
  // gaugeActiveValue = dataPourcentage * 1.8 (to get 180Deg)

  constructor() { }

  ngOnInit() {
  }

  /**
   * Update gauge-active top (when tronsform)
   * Based on transform value
   */
  getGaugeActiveTop() {
    if (this.gaugeActiveValue > 150) {
      console.log('gaugeActiveValue', this.gaugeActiveValue)
      const diff = (180 - this.gaugeActiveValue) / 5;
      console.log('diff', diff)
      return 40 + (diff * 5.3);
    } else if (this.gaugeActiveValue < 30 && this.gaugeActiveValue > 10) {
      const diff = 30 - this.gaugeActiveValue;
      return 70 + diff;
   } else if (this.gaugeActiveValue < 10) {
      return 110;
    }  else {
      return 70
    }
  }
}
